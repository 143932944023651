:root {
    --rotiboti-min-width: 300px;
    --max-content-width: 1800px;

    --rotiboti-primary-color: burlywood;
}

.App {
    display: flex;
    flex-direction: column;
}

