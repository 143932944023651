.product-menu-container {
    display: flex;
    flex-direction: column;

    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 15px bisque;
    cursor: pointer;

    /* Animation properties */
    opacity: 0;
    transform: translateX(20%);
    animation: slide-in 0.5s ease-in-out forwards;
}

@keyframes slide-in {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.product-menu-container:hover img {
    transform: scale(1.2);
}

.product-menu-container img {
    margin: 30px;
    font-weight: 500;
    transition: transform 0.3s ease;
}

@media (max-width: 499px) {
    .product-menu-container img {
        margin: 10px;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .product-menu-container:hover img {
        transform: scale(1.1);
    }
}


.product-menu-container p {
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;
}

.product-details-wrapper {
    display: flex;
    flex-direction: row;
}

.product-price-container{
    color: #909090;
}

@media (max-width: 600px) {
    .product-details-wrapper {
        flex-direction: column;
    }
    .product-details-wrapper p{
        text-align: center;
    }

    .product-price-container{
        text-align: center;
        margin-top: 0;
    }
}


.product-details-wrapper p {
    font-weight: 700;
}

.product-new-banner {
    position: absolute;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 5px;
    background-color: var(--rotiboti-primary-color);
}

.product-contents{
    & p {
        margin: 0 10px 10px 10px;
        font-weight: normal;
        text-align:justify;
        word-break: break-word;
    }
    padding-bottom: 10px;
}