.sidebar {
    width: 200px;
    background-color: rgba(243, 210, 167, 0.68);
    margin: 0 20px;
    padding: 0 10px;
    border: 2px solid rgb(253, 211, 154);
    border-radius: 10px;
}

.sidebar-item {
    padding: 10px;
    margin-bottom: 4px;
    cursor: pointer;
}

.sidebar-item:hover, .sidebar-item.selected {
    background-color: var(--rotiboti-primary-color);
    border-radius: 10px;
    font-weight: 500;
}
