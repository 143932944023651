.home-footer-container {
    display: flex;
    margin-top: 200px;
    align-items: center;
    flex-direction: column;
    background-color: #000000;
}

.laval-footer-wrapper {
    width: min(var(--max-content-width), 90%);
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
}

@media (max-width: 980px) {
    .laval-footer-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }
}

.laval-footer-language{
    display: flex;
    gap: 10px;
}

.laval-footer-language button {
    padding: 8px 16px;
    border: 2px solid burlywood;
    border-radius: 50px;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.laval-footer-language button.selected {
    background-color: burlywood;
    color: black;
    font-weight: bold;
}