/* ContactUs.css*/

.contact-us-main{
    flex: 1 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contact-us-wrapper {
    width: min(var(--max-content-width), 90%);
    margin-top: 20px;
    border-top: 8px solid var(--rotiboti-primary-color);
}

.contact-us-heading {
    font-size: 3em;
    color: #9a7644;
    text-align: center;
}


.contact-us-details p {
    text-align: center;
    font-weight: bold;
    color: #9a7644;
    padding: 10px;
}

.contact-us-details p:hover{
    border: 2px solid var(--rotiboti-primary-color);
    border-radius: 10px;
    padding: 8px;
    background-color: rgba(243, 210, 167, 0.68);
}


.contact-us-details  a {
    text-decoration: none;
    color: black;
    font-size: 1.1em;
}

.map-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}