.laval-menu-container {
    display: flex;
    flex-direction: row;
}

.laval-category-titles {
    padding-right: 20px;
}

.laval-category-sections {
    flex: 1;
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s;
}

@media (max-width: 980px) {
    .sidebar {
        display: none;
    }
}
