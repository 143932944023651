.home-menu-container {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.home-menu-wrapper {
    width: min(var(--max-content-width), 90%);
    margin-top: 20px;
}

.home-menu-heading {
    font-size: 3em;
    color: black;
    margin: 0 auto;
}
