
.category-menu-container {

}

.category-menu-grid {
    display: grid;
    --notify-list-size: calc(1 * var(--rotiboti-min-width));
    grid-template-columns: repeat(auto-fill, minmax(var(--notify-list-size), 1fr));
}

@media (max-width: 499px) {
    .category-menu-grid {
        --notify-list-size: calc(0.5 * var(--rotiboti-min-width));
        grid-template-columns: repeat(auto-fill, minmax(var(--notify-list-size), 1fr));
    }
}


.category-details-grid {
    display: grid;
    --notify-list-size: calc(1.7 * var(--rotiboti-min-width));
    grid-template-columns: repeat(auto-fill, minmax(var(--notify-list-size), 1fr));
}

@media (max-width: 600px) {
    .category-details-grid {
        --notify-list-size: calc(1 * var(--rotiboti-min-width));
        grid-template-columns: repeat(auto-fill, minmax(var(--notify-list-size), 1fr));
    }
}

.category-menu-click{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    & h2 {
        flex: 1;
        margin-left: 10px;
    }

    & svg {
        width: 35px;
        height: 35px;
        border-radius: 22px;
        padding: 4px;
        background-color: rgba(255, 228, 196, 0.6);
    }
    & :hover{
        /*box-shadow: 0 10px 15px -5px bisque;*/
    }
}

.category-menu-heading{
    font-size: 2em;
    padding-bottom: 10px;
    color: #9a7644;
    border-bottom: 2px solid var(--rotiboti-primary-color);
}

.menu-details-headings {
    margin-left: 10px;
    font-weight: bold;
    color: #9a7644;
}

.rotate180 {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
