.home-navbar-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    border-bottom: 1px solid var(--rotiboti-primary-color);
    border-top: 1px solid var(--rotiboti-primary-color);

    background-color: #000000;
}

.laval-navbar-wrapper {
    width: min(var(--max-content-width), 90%);
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.laval-navbar-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
