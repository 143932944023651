/* AboutUs.css */

.about-us-main{
    flex: 1 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about-us-wrapper {
    width: min(var(--max-content-width), 90%);
    margin-top: 20px;
    border-top: 8px solid var(--rotiboti-primary-color);
}

.about-us-heading {
    font-size: 3em;
    color: #9a7644;
    text-align: center;
}


.about-us-details {
    display: flex;
    flex-direction: row;
}

.about-us-intro {
    flex: 1;
}

.tariq-about-us {
    width: 500px;
    border-radius: 10px;
    box-shadow: 0 0 15px bisque;
}

.about-us-intro h2, strong{
    font-weight: bold;
    color: #9a7644;
}

@media (max-width: 900px) {
    .about-us-details {
        flex-direction: column;
    }
    .tariq-about-us {
        margin-top: 20px;
        width: 95%;
    }
}
